import qs from 'qs'

export default class UsuarioService {
  constructor(http) {
    this._http = http
  }

  async getListaUsuarios() {
    const { data } = await this._http.get('/api/usuarios/list')
    return data
  }

  async getUsuarios(dados) {
    const { data } = await this._http.get(
      `/api/usuarios/${dados.page}/${dados.rows}`,
    )
    return data
  }

  async getListaUsuariosConsignatarias() {
    const { data } = await this._http.get('/api/usuarios-consignatarias/list')
    return data
  }

  async getListaUsuariosConsignatariaLogada() {
    const { data } = await this._http.get('/api/usuarios/consignataria')
    return data
  }

  async getListaConsignatariaDoGrupoDaConsignatariaLogada() {
    const { data } = await this._http.get('/api/consignatarias/grupo')
    return data
  }

  async getUsuariosPorConsignataria(dados, idConsignataria) {
    const { data } = await this._http.get(
      `/api/usuarios/page/${dados.page}/size/${dados.rows}/consignataria/${idConsignataria}`,
    )
    return data
  }

  async getUsuariosPorIdConsignataria(idConsignataria) {
    const { data } = await this._http.get(
      `/api/usuarios/consignataria/${idConsignataria}`,
    )
    return data
  }

  async saveUsuario(usuario) {
    if (usuario.id) {
      return await this._http.put('/api/usuarios', usuario)
    } else {
      return await this._http.post('/api/usuarios', usuario)
    }
  }

  async saveUsuarioConsignataria(usuario, ativo) {
    if (usuario.id) {
      return this._http.put(
        `/api/usuarios-consignatarias?ativo=${ativo}`,
        usuario,
      )
    } else {
      return this._http.post('/api/usuarios-consignatarias', usuario)
    }
  }

  async saveUsuarioConsignatariaPelaGestao(usuario, ativo) {
    if (usuario.id) {
      return this._http.put(
        `/api/usuarios-consignatarias/gestao?ativo=${ativo}`,
        usuario,
      )
    } else {
      return this._http.post('/api/usuarios-consignatarias/gestao', usuario)
    }
  }

  async deleteUsuario(usuario) {
    await this._http.delete(`/api/usuarios/${usuario.id}`)
  }

  async deleteUsuarioConsignataria(usuario) {
    await this._http.delete(`/api/usuarios-consignatarias/${usuario.id}`)
  }

  async getUsuario(id) {
    const { data } = await this._http.get(`/api/usuarios/${id}`)
    return data
  }

  async getKeycloakUsers(filtro) {
    const { data } = await this._http.get(`/api/sso/usuarios?buscar=${filtro}`)
    return data
  }

  async gerarNovaSenha(usuario) {
    await this._http.put('/api/usuarios/gerar-senha', usuario)
  }

  async ativarDesativar(usuario) {
    await this._http.put('/api/usuarios/ativar-desativar', usuario)
  }

  async adicionarRemoverAcessoConsignataria(usuario) {
    await this._http.put(
      '/api/usuarios/adiciona-remove-acesso-consignataria',
      usuario,
    )
  }

  async hasAcessoConsignataria(keycloakId) {
    const { data } = await this._http.get(
      `/api/usuarios/has-acesso-consignataria?keycloakId=${keycloakId}`,
    )
    return data
  }

  async isAtivoKeycloak(keycloakId) {
    const { data } = await this._http.get(
      `/api/usuarios/is-ativo?keycloakId=${keycloakId}`,
    )
    return data
  }

  async alterarPermissao(permissao) {
    await this._http.put('/api/usuarios/alterar-permissao', permissao)
  }

  async alterarPermissoes(usuarioPermissoes) {
    await this._http.put('/api/usuarios/alterar-permissoes', usuarioPermissoes)
  }

  async getListaRolesUsuario(usuario) {
    const { data } = await this._http.get(`/api/usuarios/roles/${usuario.id}`)
    return data
  }

  async buscarUsuarioAll(params) {
    const { data } = await this._http.get(`/api/usuarios/all`, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, {
          arrayFormat: 'brackets',
          allowDots: true,
        })
      },
    })
    return data
  }

  async buscarUsuarioGestao(params) {
    const { data } = await this._http.get(`/api/usuarios/gestao`, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, {
          arrayFormat: 'brackets',
          allowDots: true,
        })
      },
    })
    return data
  }

  async getListaEntidadesPorUsuarioGestaoEntidadeLogada() {
    const { data } = await this._http.get(
      '/api/usuarios-gestao-entidade/entidades',
    )
    return data
  }
}
